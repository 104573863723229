import md5 from 'js-md5'
export var SearchList = [
  { labe: '合同编号', code: 'number', type: 'input' },
  {
    labe: '合同类型',
    code: 'contractType',
    type: 'select',
    option: [
      { label: '全部', value: '' },
      { label: '订单', value: '1' },
      { label: '框架合同', value: '2' },
      { label: '订购合同', value: '3' },
      { label: '非标合同', value: '4' }
    ]
  },
  {
    labe: '业务类型',
    code: 'businessType',
    type: 'select',
    option: [
      { label: '全部', value: '' },
      { label: '生产物料类', value: '1' },
      { label: '资产类', value: '2' },
      { label: '服务类', value: '3' },
      { label: '基建类', value: '4' },
      { label: '委托采购', value: '5' },
      { label: '其它协议', value: '6' }
    ]
  },
  { labe: '供应商主体', code: 'subject', type: 'input', placeholder: '编码/名称' },
  {
    labe: '我方主体',
    code: 'mySubject',
    type: 'select',
    option: [
    ]
  },
  {
    labe: '合同状态',
    code: 'status',
    type: 'select',
    option: [
      { label: '全部', value: '' },
      { label: '待审批', value: '0' },
      { label: '驳回', value: '1' },
      { label: '待签章', value: '2' },
      { label: '待他签', value: '3' },
      { label: '已签章', value: '4' },
      { label: '草稿', value: '5' },
      { label: '作废', value: '6' },
      { label: '待确认', value: '7' },
      { label: '待归档', value: '8' }
    ]
  },
  {
    labe: '生效日期',
    code: 'createDate',
    type: 'daterange'
  }
]
export var SearchData = {
  number: '',
  contractType: '',
  businessType: '',
  subject: '',
  mySubject: '',
  status: '',
  strStartDate: '',
  strEndDate: '',
  differentiatedContract: 0,
  type: 0
}
export var tableField = [
  { label: '合同编号', code: 'number', type: 'input', width: '60%' },
  {
    label: '合同类型',
    code: 'contractType',
    type: 'function',
    width: '55%',
    handle: (index, data) => {
      return data.contractType === '1' ? '订单' : data.contractType === '2' ? '框架合同'
        : data.contractType === '3' ? '订购合同' : data.contractType === '4' ? '非标合同' : data.contractType
    }
  },
  {
    label: '业务类型',
    code: 'businessType',
    type: 'function',
    width: '55%',
    handle: (index, data) => {
      return data.businessType === '1' ? '生产物料类' : data.businessType === '2' ? '资产类'
        : data.businessType === '3' ? '服务类' : data.businessType === '4' ? '基建类'
          : data.businessType === '5' ? '委托采购' : data.businessType === '6' ? '其它协议' : data.businessType
    }
  },
  { label: '合同名称', code: 'name', type: 'input' },
  {
    label: '我方主体',
    code: 'mySubjectCode',
    type: 'vnode',
    width: '140',
    handle: function (scope) {
      if (scope) {
        return {
          data () {
            if (scope.row.mySubjectCode == null || undefined) {
              scope.row.mySubjectCode = ''
            }
            return {
              mySubjectCode: scope.row.mySubjectCode.split('-')[1]
            }
          },
          template: '<span>{{mySubjectCode}}</span>'
        }
      }
    }
  },
  { label: '供应商主体', code: 'subject', type: 'input', width: '130' },
  {
    label: '合同介质',
    code: 'medium',
    type: 'function',
    width: '55%',
    handle: (index, data) => {
      return data.medium === 0 ? '纸质合同' : '电子合同'
    }
  },
  {
    label: '签章方式',
    code: 'signature',
    type: 'function',
    width: '',
    handle: (index, data) => {
      return data.signature === 0 ? '签约平台签章' : data.signature === 1 ? '用印平台签章' : data.signature
    }
  },
  { label: '负责人', code: 'jpersonincharge', type: 'input', width: '45%' },
  {
    label: '状态',
    code: 'status',
    type: 'function',
    width: '45%',
    handle: (index, data) => {
      const color = md5(data.status + '').substring(0, 6)
      const label = data.status === 0 ? '待审批' : data.status === 1 ? '驳回'
        : data.status === 2 ? '待签章' : data.status === 3 ? '待他签'
          : data.status === 4 ? '已签章' : data.status === 5 ? '草稿'
            : data.status === 6 ? '作废' : data.status === 7 ? '待确认' : data.status === 8 ? '待归档' : data.status
      const tblab = `<div class="dotlab"><div class="dot" style="background:#${color}"></div>${label}</div>`
      return tblab
    }
  },
  {
    label: '操作',
    code: 'act',
    width: '45%',
    type: 'action',
    actionData: [
      {
        actionLabel: '查看',
        operationButton: 'see'
      }
      // {
      //   actionLabel: '作废',
      //   operationButton: 'invalid',
      //   stateField: 'status',
      //   stateSymbol: '=',
      //   stateValue: '1'
      // }
    ]
  }
]
export var tableData = []

export var menudata = [{
  label: '+起草合同',
  action: 'draftContract',
  id: 1
}
]
