<template>
    <div>
      <!--  运营管理合同列表 -->
      <list-search :childData="childData" @onSearch="search"  @clearSearch="search"></list-search>
      <div class="contractMenu">
        <list-menu @buttonClick="buttonClick" :menuData="menudata" ></list-menu>
        <el-button @click="handlePreview " class="derivebtn" type="text" >导出</el-button>
      </div>
      <global-table :tableField="tableField"  :tableData="tableData" :paginationData="paginationData" @tableAction="tableAction" @pageChange="pageChange"></global-table>
    </div>
</template>

<script>
import { exportForm, request, requestForm } from '@/assets/js/http.js'
import { MIME } from '@/assets/js/mime.js'
import Component from '@/assets/js/components.js'
import { SearchList, SearchData, tableField, menudata, tableData } from './js/ContractList.js'
import qs from 'qs'
import dayjs from 'dayjs'
export default {

  components: Component.components,
  name: 'ContractList',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 340) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      childData: { searchList: SearchList, searchData: SearchData },
      tableField: tableField,
      menudata: menudata,
      tableData: tableData,
      searchdata: SearchData,
      paginationData: {
      },
      pageNum: 1,
      pageSize: pageSize
    }
  },
  created () {
    this.getContractList()
  },
  mounted () {
    request('/api/sap/businessScope/getBusinessScopeList', 'get').then((response) => {
      this.childData.searchList[4].option = [{ label: '全部', value: '' }]
      for (var item of response) {
        var tempdata = {}
        tempdata.label = item.companyDesc
        tempdata.value = item.businessScope
        this.childData.searchList[4].option.push(tempdata)
      }
    })
  },
  methods: {
    getContractList () {
      var pageSize = parseInt((document.documentElement.clientHeight - 340) / 42)
      if (pageSize < 10) {
        pageSize = 10
      }
      const obj = {
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        type: this.searchdata.type
      }
      requestForm('/api/pact/contract/queryAll', 'post', obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
          this.paginationData = { pageSize: pageSize, total: res.data.total }
        }
      })
    },
    tableAction: function (index, data, clickBtn) {
      if (clickBtn.operationButton === 'see') {
        this.$router.push({ params: { viewName: 'DraftaContract' }, query: { id: data.id } })
      } else if (clickBtn.operationButton === 'invalid') { // 审批驳回以后,显示作废按钮

      }
    },
    buttonClick: function (data) {
      this.$router.push({ params: { viewName: 'DraftaContract' } })
    },
    handlePreview () {
      var file = { name: '合同列表.xls' }
      exportForm('/api/pact/contract/poiOperate?' + qs.stringify(this.searchdata), 'post').then(
        (response) => {
          var fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
          var mime = 'application/octet-stream'
          if (MIME[fileExtension]) {
            mime = MIME[fileExtension]
          }
          const aBlob = new Blob([response.data], { type: mime })
          this.downloadByBlob(aBlob, file.name, mime)
        })
    },
    pageChange (data) {
      this.searchdata.pageNum = data
      this.searchdata.pageSize = this.pageSize
      requestForm('/api/pact/contract/queryAll', 'post', this.searchdata).then((Response) => {
        if (Response.code === '200') {
          this.tableData = Response.data.records
          this.paginationData = { pageSize: this.pageSize, total: Response.data.total }
        }
      })
    },
    dateFormate: function (data) {
      return dayjs(data).format('YYYY-MM-DD')
    },
    search: function (data) {
      data.pageNum = this.pageNum
      data.pageSize = this.pageSize
      data.type = 0
      this.searchdata = { ...data }
      delete this.searchdata.createDate
      if (data.createDate) {
        this.searchdata.strEndDate = this.dateFormate(data.createDate[1])
        this.searchdata.strStartDate = this.dateFormate(data.createDate[0])
      }
      requestForm('/api/pact/contract/queryAll', 'post', this.searchdata).then((Response) => {
        if (Response.code === '200') {
          this.tableData = Response.data.records
          this.paginationData = { pageSize: this.pageSize, total: Response.data.total }
        }
      })
    },
    downloadByBlob (data, fileName, mime) {
      try {
        const url = window.URL.createObjectURL(data)
        const eleLink = document.createElement('a')
        eleLink.href = url
        eleLink.target = '_blank'
        if (mime.indexOf('image') < 0 && mime.indexOf('pdf') < 0) {
          eleLink.download = fileName
        }
        eleLink.click()
        window.URL.revokeObjectURL(url)
      } catch (error) {
        console.error('download function error!', error)
      }
    }
  }

}

</script>
<style scoped lang="scss">
.contractMenu{
 position: relative;
 .derivebtn{
  position: absolute;
  right: 35px;
  top: 8px;
  font-size: 16px;
 }
}
</style>
